import PropTypes from 'prop-types';
import styled from 'styled-components';
import { handleApiResponse } from '../../lib/jsonapi';
import useI18n from '../../lib/use_i18n';
import { signIn } from '../../store';
import { CheckboxField, Form, InputField } from '../common/form';
import { Flex } from '../common/styles/flex';
import { FormRow, RowsWrapper } from '../common/styles/form';
import { StyledButton } from './style';
import { Wrapper } from './wrapper';

const ResetPassword = styled.a`
  position: absolute;
  right: 0;
  width: fit-content;
`;

export const SignIn = ({
  signUpUrl,
  resetPasswordUrl,
  notificationProps,
  goHiringUrl,
  signupEnabled,
}) => {
  const { translate } = useI18n('access');
  return (
    <Wrapper
      goHiringUrl={goHiringUrl}
      notificationProps={notificationProps}
      subtitle={translate('.subtitle')}
      title={translate('.sign_in_title')}
    >
      <Form
        onSubmit={async (values) => {
          const errors = await handleApiResponse(
            signIn(values),
            ({ headers }) => window.location.assign(headers?.location)
          );
          return errors;
        }}
      >
        {({ handleSubmit }) => {
          return (
            <RowsWrapper>
              <FormRow>
                <InputField
                  customMessage={translate('.form.email.error')}
                  label={translate('.form.email.label')}
                  name="email"
                  required={true}
                />
              </FormRow>
              <FormRow position="relative">
                <InputField
                  customMessage={translate('.form.password.error')}
                  label={translate('.form.password.label')}
                  name="password"
                  required={true}
                  type="password"
                />
                <ResetPassword href={resetPasswordUrl}>
                  {translate('.request_reset_password')}
                </ResetPassword>
              </FormRow>
              <CheckboxField
                name="remember"
                label={translate('.form.remember.label')}
              />
              <StyledButton className="btn btn-primary" onClick={handleSubmit}>
                {translate('.form.login_button')}
              </StyledButton>
            </RowsWrapper>
          );
        }}
      </Form>
      {signupEnabled && (
        <Flex marginTop="16px" gap="4px">
          <span>{translate('.not_signed_up')}</span>
          <a href={signUpUrl}>{translate('.create_account')}</a>
        </Flex>
      )}
    </Wrapper>
  );
};

SignIn.propTypes = {
  resetPasswordUrl: PropTypes.string,
  signUpUrl: PropTypes.string,
  signupEnabled: PropTypes.bool,
  ...Wrapper.propTypess,
};

SignIn.defaultProps = {
  resetPasswordUrl: '/resets',
  signUpUrl: '/signup',
};

export const SIGN_IN_URL = '/signin';
