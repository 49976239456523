import { useIntl } from 'react-intl';
import selectUnit from './helpers/select_unit';

/**
 * A formatMessage method supplied by react-intl works with objects like this:
 *
 *     { id: 'app.actions.edit', defaultMessage: 'Edit' }
 *
 * Usually, this kind of objects could be retrieved from a `intl/default.js` file,
 * however, that file isn't loaded anymore, we load only translations for a certain locale,
 * there aren't missing translations, a Ruby part takes case of providing defaults.
 * So, the method below constructs the mentioned object.
 */
const translateWithFormatMsg = (formatMessage, id, opts) => {
  if (window.phraseEnabled) {
    return `{{__phrase_${id}__}}`;
  } else {
    return formatMessage({ id }, opts);
  }
};

/**
 * Allows for something similar to rails i18n, where a namespace is
 * automatically used for ids beginning with '.'
 */
const translateWithNamespace = (namespace, formatMessage, id, opts) => {
  if (id.startsWith('.')) id = `${namespace}${id}`;

  return translateWithFormatMsg(formatMessage, id, opts);
};

/**
 * This method only exists because of components which still inject the intl object via props.
 * Mostly, it is true for class-based components, hooks cannot be used with them.
 */
const translate = (intl, id, opts) =>
  translateWithFormatMsg(intl.formatMessage, id, opts);

/**
 * Represents a number with the currency.
 */
const numberToCurrency = (formatNumber, number) =>
  formatNumber(number, { style: 'currency', currency: 'EUR' });

const defaultOptions = { year: 'numeric', month: 'short', day: 'numeric' };

const dateToString = (formatDate, date, options = {}) =>
  formatDate(date, Object.assign({}, defaultOptions, options));

// gets date in format: YYYY-mm-dd
const dateInDbFormat = (date) => date.toISOString().substr(0, 10);

const relativeOrFormatDate = (translate, dateToString, date) => {
  const todayInStr = dateInDbFormat(new Date());

  if (date.search(todayInStr) > -1) return translate('app.today');

  const yesterdayInStr = dateInDbFormat(
    new Date(new Date().getTime() - 86400 * 1000)
  );

  if (date.search(yesterdayInStr) > -1) return translate('app.yesterday');

  return dateToString(date);
};

/**
 * Returns a String representing time in words.
 */
const timeToDistance = (formatRelativeTime, date) => {
  const { value, unit } = selectUnit(new Date(date));
  return formatRelativeTime(value, unit);
};

export const generateLink = ({ href, text }) =>
  `<a href="${href}" target="_blank" rel="noreferrer">${text}</a>`;

// we often want to show today/yasterday for recent dates,
// instead of displaying the date itself.
const customRelativeDate = (
  elasticDateFormat,
  formatRelativeTime,
  formatTime,
  { dateString, showTime }
) => {
  if (!dateString) return;

  const time = showTime ? `, ${formatTime(dateString)}` : '';
  const distance = Math.ceil(
    (new Date() - new Date(dateString)) / (86400 * 1000)
  );

  const date =
    distance > 2 && distance < 8
      ? formatRelativeTime(distance * -1, 'day')
      : elasticDateFormat(dateString);

  return `${date}${time}`;
};

/**
 * A hook which proxies method calls to react-intl
 * and provides access to the translate method.
 * An optional default namespace can be supplied,
 * which will be prefixed to any given id that starts
 * with the '.' character.
 * Translations can be requested like:
 *
 *     let { translate } = useI18n();
 *     translate('app.actions.edit')
 *
 *     let { translate } = useI18n('app.actions');
 *     translate('.edit'); // Evaluates to app.actions.edit
 *     translate('no.prefix'); // Evaluates to no.prefix
 *
 *     let { formatCurrency } = useI18n();
 *     formatCurrency(300) // €300.00
 */
const useI18n = (namespace) => {
  const intl = useIntl();
  const {
    formatMessage,
    formatNumber,
    formatRelativeTime,
    formatDate,
    formatTime,
    locale,
  } = intl;

  const translate = namespace
    ? translateWithNamespace.bind(null, namespace, formatMessage)
    : translateWithFormatMsg.bind(null, formatMessage);

  const formatCurrency = numberToCurrency.bind(null, formatNumber);
  const timeAgo = timeToDistance.bind(null, formatRelativeTime);
  const formattedDate = dateToString.bind(null, formatDate);
  const elasticDateFormat = relativeOrFormatDate.bind(
    null,
    translate,
    formattedDate
  );

  const formatRelativeDate = customRelativeDate.bind(
    null,
    elasticDateFormat,
    formatRelativeTime,
    formatTime
  );
  return {
    translate,
    formatNumber,
    formatCurrency,
    formatTime,
    formatRelativeTime,
    formatRelativeDate,
    timeAgo,
    formattedDate,
    elasticDateFormat,
    generateLink,
    locale,
  };
};

export default useI18n;

export { translate };
